/**
 * Use this file to overwrite, add or reset any CSS rules.
 * There are also a bunch of CSS theme variables, that can be overwritten here.
 */

:root {
  --flown-focusDark: #4cbab8;
  --flown-infoMid35: color-mix(in srgb, #3f6de4, transparent 35%);
}

/*
  Change the spacing between the tiles in the grid
*/
div.layout-container > div.call > .main > .grid {
  --grid-gap: 8px;
}

/*
  Add rounded borders to each of the tiles in the grid
*/
div.layout-container > div.call > .main > .grid > .tiles > .tile,
div.layout-container > div.call > .main > .grid > .tiles > .tile > video,
div.layout-container
  > div.call
  > .main
  > .grid
  > .tiles
  > .tile
  > .content
  > div.activeBorder {
  border-radius: 16px;
}

div.layout-container > div.call > .main > .grid > .tiles > .tile > video {
  border-radius: 16px;
}
